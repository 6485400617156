<template>
    <div class="main_container">
        <div class="manage_container">
            <div class="manage">出版社名称:</div>
           <span class="manage_info">{{publishing}}</span>
        </div>
        <div class="manage_container">
            <div class="manage">单元名称:</div>
            <el-input class="manage_input" type="text" size="small" v-model="unitName"></el-input>
        </div>
        <el-button type="primary" class="confirm_button" @click="onConfirm">确认</el-button>
        <el-button type="danger" @click="oncancel">取消</el-button>

    </div>

</template>

<script>
    import axios from '@/libs/axios'
    import {compressFile} from "@/utils/fileUtil";
    import {getData} from '@/utils/cookies'

    export default {
        name: "add",

        data() {
            return {
                publishing:'',//出版社
                unitName: '',//单元名称
                id:'',//单元id
                name: '语文',//教材名称（语文）
                subjectId: '1',//学科Id（语文为1）
                imageUrl: '', // OSS 图片地址 https://www.aliyun.com/a.png
                localImageUrl: '', // 本地图片地址 blob:http://localhost:8081/771cddf2-6caf-461b-bd02-7c78057faed9
                loading: false,


            }
        },
        mounted() {
            console.log(getData('unitInfo'));;
            this.publishing=getData('unitInfo').publishing;
            this.unitName=getData('unitInfo').name;
            this.id=getData('unitInfo').id;
        },
        methods: {
            //确认添加
            onConfirm() {
                if (!this.unitName) {
                    this.$message.warning('请输入单元名称');
                } else {
                    this.$store.dispatch("chinese/updateUnit", {
                        'id': this.id,
                        'name': this.unitName,
                    }).then(() => {
                        this.$router.go(-1);
                    });
                }
            },
            //取消添加
            oncancel() {
                this.$router.go(-1);
            },
            onSemester() {
                this.$store.dispatch('chinese/bookList', {'semesterId': this.semesterId})
                this.bookId=''
            }


        }
    }
</script>

<style scoped lang="scss">
    .main_container {
        width: 100%;
        padding: 20px;

        .manage_container {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            font-size: 16px;

            .manage {
                width: 150px;
                text-align: right;
                margin-right: 30px;
            }
            .manage_info{
                font-size: 14px;
                color: #606266;
            }

            .manage_input {
                display: inline-block;
                width: 300px;
            }
        }

        .manage {
            width: 150px;
            text-align: right;
        }

        .confirm_button {
            margin-left: 100px;
            margin-right: 50px;
            margin-top: 100px;
        }

        .image-upload, .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 12px;
            cursor: pointer;
            overflow: hidden;
            display: inline-block;
            width: 105px;
            height: 150px;

            .image {
                width: 105px;
                height: 150px;
                display: block;
            }

        }

    }

</style>
